let BASE_SERVER_URL;
let KEY;
let GOOGLE_AUTH_ID;
let FXS, ANNOTATIONS;
let LENS_ENV_VALUE;

if (process.env.REACT_APP_ENV === 'stage') {
  BASE_SERVER_URL = 'https://staging.async-fx.attentive.ai/api';
  KEY = '2C5DCD5ECF2D71CAD183F7B946184';
  GOOGLE_AUTH_ID = '674328735709-hpj4ch87bd62anqdpf6hpkm113khvm6p.apps.googleusercontent.com';
  FXS = 'https://staging.dashboard.fxs.attentive.ai/';
  ANNOTATIONS = 'https://annotations-stage.attentive.ai/';
  LENS_ENV_VALUE = "stage";
} else if (process.env.REACT_APP_ENV === 'prod') {
  BASE_SERVER_URL = 'https://async-fx-prod.attentive.ai/api';
  KEY = 'CADC8B21F863113A48F7ADF178F63';
  GOOGLE_AUTH_ID = '674328735709-hpj4ch87bd62anqdpf6hpkm113khvm6p.apps.googleusercontent.com';
  FXS = 'https://dashboard.fxs.attentive.ai/';
  ANNOTATIONS = 'https://annotations.attentive.ai/';
  LENS_ENV_VALUE = "";
} else if (process.env.REACT_APP_ENV === 'qa') {
  BASE_SERVER_URL = 'https://qa.async-fx.attentive.ai/api';
  KEY = '1B25996595D7E3A8EF9997B87924A';
  GOOGLE_AUTH_ID = '641659546390-mbg8ll8coip9gqjd9lcb4h6j5aruuacv.apps.googleusercontent.com';
  FXS = 'https://qa.dashboard.fxs.attentive.ai/';
  ANNOTATIONS = 'https://qa.annotations.attentive.ai/';
  LENS_ENV_VALUE = "qa";
} else {
  BASE_SERVER_URL = 'https://staging.async-fx.attentive.ai/api';
  KEY = '436236939443955C11494D448451F';
  GOOGLE_AUTH_ID = '481344270506-hu8h4ntrina4l210m7m7hruviltskika.apps.googleusercontent.com';
  FXS = 'https://staging.dashboard.fxs.attentive.ai/';
  ANNOTATIONS = 'https://dev.annotations.attentive.ai/';
  LENS_ENV_VALUE = "stage";
}

export const BASE_URL = BASE_SERVER_URL;
export const API_KEY = KEY;

export const GET_REQUESTS = '/requests';
export const REQUEST = '/request/';

export const RESTART = '/request/restart';
export const RESUME = '/resume';
export const PAUSE = '/pause';
export const CANCEL = 'cancel';
export const SOURCE_TYPE = '/source_type';

export const LOCAL_PATHNAMES = {
  IN_DETAIL: '/in-detail',
  RAW: '/raw'
};

export const GOOGLE_LOGIN_CLIENT_ID = GOOGLE_AUTH_ID;

export const FXS_LINK = FXS;
export const ANNOTATION_LINK = ANNOTATIONS;

export const LENS_ENV = LENS_ENV_VALUE;

export const UPDATE_REQUEST_PRIORITY = 'requests/%s/priority';
export const BULK_PRIORITY_UPDATE = '/requests/bulk_priority_update';

