import React, { useEffect, useState } from 'react';
import { Table, Input, DatePicker, Button, Tooltip, message, Select, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  PlayCircleOutlined,
  RedoOutlined,
  PauseCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined
} from '@ant-design/icons';
import {
  BASE_URL as BASEURL,
  GET_REQUESTS,
  RESTART,
  PAUSE,
  RESUME,
  REQUEST,
  CANCEL,
  SOURCE_TYPE,
  LENS_ENV
} from '../../Constants/URLs';
import { getAPI, patchAPI, postAPI } from '../../Services/Api';
import {
  GET_STATUS,
  REQUEST_RESULTS,
  REQUEST_RESULTS_METADATA,
  REQUEST_STATUS,
  REQUEST_STATUS_VALUES,
  GET_STATUS_COLORS,
  PRIORITY_RANGE_MINIMUM,
  PRIORITY_RANGE_MAXIMUM
} from '../../Constants/Constants';
import { getDateTimeString } from '../../Utils/HelperFunctions';
import LayoutComponent from '../Components/Layout';
import { PriorityComponent } from '../Components/PriorityComponent';
import PriorityPopover from '../Components/PriorityPopover';
import FileUploadModal from '../../Components/FileUploadModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

function generate_lens_link(request_id){

  if (LENS_ENV === ""){
    return `https://lens.attentive.ai/shared-view?id=${request_id}`
  }
  return `https://lens.attentive.ai/shared-view?id=${request_id}&source=${LENS_ENV}`
}

const Home = () => {
  const [searchValue, setSearchValue] = useState('');
  const [requestDetails, setRequestDetails] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [priorityFilter, setPriorityFilter] = useState([PRIORITY_RANGE_MINIMUM, PRIORITY_RANGE_MAXIMUM]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  // eslint-disable-next-line
  const [pageDetails, setPageDetails] = useState({ page: 1, pageSize: 10, count: 0 });
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [requestStatus, setRequestStatus] = useState(undefined);
  const [dateRange, setDateRange] = useState({ from_date: null, to_date: null });
  const [dateRangeChosen, setDateRangeChosen] = useState(false);
  // add params state var here

  const [sourceTypes, setSourceTypes] = useState([]);
  const [sourceTypeLoading, setSourceTypeLoading] = useState(false);
  const [selectedSourceType, setSelectedSourceType] = useState(undefined);

  useEffect(() => {
    getRequests();
    // eslint-disable-next-line
  }, [refreshComponent]);

  useEffect(() => {
    getSourceTypes();
  }, []);

  const columns = [
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      ellipsis: true,
      render: (text, record) => (
        <>
          <PriorityComponent request={record} priority={text} status={record.status} />
        </>
      )
    },
    {
      title: 'ID',
      dataIndex: 'request_id',
      key: 'id',
      ellipsis: true
    },
    {
      title: 'Status',
      key: 'status',
      ellipsis: true,
      render: (text, record) => <Tag color={GET_STATUS_COLORS[record.status]}>{GET_STATUS[record.status]}</Tag>
    },
    {
      title: 'Actions',
      key: 'actions',
      ellipsis: true,
      render: (text, record) => {
        // only for failed show restart.
        // completed -> no action
        // in progress , pending -> pause/resume action

        let component = <Button type='dashed' icon={<PlayCircleOutlined />} shape='circle' disabled></Button>;
        if (record.status === REQUEST_STATUS_VALUES.PENDING) {
          component = (
            // Space is ANT component
            <Space>
              <Tooltip title='Pause this request'>
                <Button
                  type='primary'
                  icon={<PauseCircleOutlined />}
                  shape='circle'
                  onClick={e => requestActionHandler(e, record, PAUSE)}></Button>
              </Tooltip>
              <Tooltip title='Cancel this request'>
                <Button
                  type='primary'
                  icon={<CloseOutlined />}
                  shape='circle'
                  danger={true}
                  onClick={e => requestActionHandler(e, record, CANCEL)}></Button>
              </Tooltip>
            </Space>
          );
        } else if (record.status === REQUEST_STATUS_VALUES.IN_PROGRESS) {
          component = (
            // Space is ANT component
            <Space>
              <Tooltip title='Pause this request'>
                <Button
                  type='primary'
                  icon={<PauseCircleOutlined />}
                  shape='circle'
                  onClick={e => requestActionHandler(e, record, PAUSE)}></Button>
              </Tooltip>
              <Tooltip title='Cancel this request'>
                <Button
                  type='primary'
                  icon={<CloseOutlined />}
                  shape='circle'
                  danger={true}
                  onClick={e => requestActionHandler(e, record, CANCEL)}></Button>
              </Tooltip>
            </Space>
          );
        } else if (record.status === REQUEST_STATUS_VALUES.COMPLETED) {
          component = <Button type='dashed' icon={<PlayCircleOutlined />} shape='circle' disabled></Button>;
        } else if (record.status === REQUEST_STATUS_VALUES.FAILED) {
          component = (
            <Tooltip title='Restart this request'>
              <Button
                type='primary'
                icon={<RedoOutlined />}
                shape='circle'
                onClick={e => requestActionHandler(e, record, RESTART)}></Button>
            </Tooltip>
          );
        } else if (record.status === REQUEST_STATUS_VALUES.PAUSED) {
          component = (
            <Tooltip title='Resume this request'>
              <Button
                type='primary'
                icon={<PlayCircleOutlined />}
                shape='circle'
                onClick={e => requestActionHandler(e, record, RESUME)}></Button>
            </Tooltip>
          );
        }

        return component;
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: true,
      render: (text, record) => <p>{getDateTimeString(text, true)}</p>,
      sorter: {
        compare: (a, b) => new Date(a.created_at) - new Date(b.created_at)
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Source Type',
      key: 'source_type',
      ellipsis: true,
      render: (text, record) => <p>{text.source_type}</p>
    },
    {
      title: 'Detailed View',
      key: 'indetail',
      ellipsis: true,
      render: (text, record) => {
        let component = <Link to={'request/in-detail/' + record.request_id}>View</Link>;
        if (text.status === REQUEST_STATUS_VALUES.PENDING) {
          component = <p>Data not generated yet</p>;
        }
        return component;
      }
    },
    {
      title: 'Shareable Link',
      key: 'lens',
      ellipsis: true,
      render: (text, record) => {
        let component = <p>Link not updated</p>;
        if (text.status === REQUEST_STATUS_VALUES.COMPLETED || text.status === REQUEST_STATUS_VALUES.POLING) {
          component = (
            <a href={generate_lens_link(record.request_id)} target='_blank' rel='noreferrer'>
              View in Lens
            </a>
          );
        }
        return component;
      }
    }
  ];

  const getSourceTypes = () => {
    setSourceTypeLoading(true);

    getAPI(SOURCE_TYPE)
      .then(res => {
        const { source_types: sourceTypes } = res;
        const result = sourceTypes.reduce((acc, curr) => {
          acc.push(curr['name']);
          return acc;
        }, []);
        setSourceTypes(result);
      })
      .finally(() => setSourceTypeLoading(false));
  };

  const getRequests = (pageNo = pageDetails.page, pageSize = pageDetails.pageSize) => {
    setTableLoading(true);

    let params = {
      page: pageNo,
      page_size: pageSize,
      status: requestStatus,
      from_date: dateRange.from_date,
      to_date: dateRange.to_date,
      ...(searchValue && { search: searchValue }),
      ...(selectedSourceType && { request_source_type: selectedSourceType }),
      ...(priorityFilter.length > 0 && { min_priority: priorityFilter[0], max_priority: priorityFilter[1] })
    };

    getAPI(GET_REQUESTS, params)
      .then(res => {
        setRequestDetails(res[REQUEST_RESULTS]);
        setPageDetails(prevState => ({ ...prevState, count: res[REQUEST_RESULTS_METADATA]?.total_count }));
      })
      .finally(() => setTableLoading(false));
  };

  const onSearch = event => {
    if (event.keyCode === 13) {
      let searchInput = searchValue.trim();
      setSearchValue(searchInput);
      setRefreshComponent(prevState => !prevState);
    }
  };

  const requestActionHandler = (event, { request_id }, action) => {
    setTableLoading(true);
    let payload = {};

    let url = BASEURL + REQUEST + request_id;
    if (action === RESTART) url = BASEURL + RESTART;
    else if (action === PAUSE) url = url + PAUSE;
    else if (action === RESUME) url = url + RESUME;
    else if (action === CANCEL) url = BASEURL + REQUEST + CANCEL;

    if (action === PAUSE || action === RESUME) {
      patchAPI(url, payload)
        .then(() => {
          let messageText;
          if (action === PAUSE) messageText = `${request_id} paused successfully`;
          else if (action === RESUME) messageText = `${request_id} resumed successfully`;
          message.success(messageText, 1);
          setRefreshComponent(prevState => !prevState);
        })
        .finally(() => {
          setTableLoading(false);
        });
    } else {
      let user = JSON.parse(sessionStorage.getItem('user'));
      console.log(user);
      payload = {
        request_id,
        user_name: user.email
      };
      if (action === RESTART) {
        postAPI(url, payload)
          .then(() => {
            message.success(`${request_id} restarted successfully`, 1);
            setRefreshComponent(prevState => !prevState);
          })
          .finally(() => {
            setTableLoading(false);
          });
      } else if (action === CANCEL) {
        patchAPI(url, payload)
          .then(() => {
            message.success(`${request_id} Cancelled successfully`, 1);
            setRefreshComponent(prevState => !prevState);
          })
          .finally(() => {
            setTableLoading(false);
          });
      }
    }
  };

  const datePickerhandler = ([from, to]) => {
    let fromDate = getDateTimeString(from._d, false, true, true);
    let toDate = getDateTimeString(to._d, false, true, true);

    fromDate = fromDate.split('-').reverse().join('-');
    toDate = toDate.split('-').reverse().join('-');
    setDateRange({ from_date: fromDate, to_date: toDate });
    setDateRangeChosen(true);
    setRefreshComponent(prevState => !prevState);
  };
  const handlePriorityFilter = () => {
    setRefreshComponent(prevState => !prevState);
  };
  const handleClearPriority = () => {
    setPriorityFilter([PRIORITY_RANGE_MINIMUM, PRIORITY_RANGE_MAXIMUM]);
    setRefreshComponent(prevState => !prevState);
    setPopoverVisible(false);
  };
  return (
    <LayoutComponent>
      <div className='parent-container'>
        <div className='input-box'>
          <Input
            placeholder='Input Id'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={onSearch}
          />
        </div>
        <div style={{ display: 'flex', gap: '16px' }}>
          <FileUploadModal />
          <Space>
            <RangePicker onChange={datePickerhandler} allowClear />
            {dateRangeChosen && (
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => {
                  setDateRange({ to_date: null, from_date: null });
                  setRefreshComponent(prevState => !prevState);
                  setDateRangeChosen(false);
                }}></Button>
            )}
          </Space>
          {searchValue.length !== 0 && (
            <Button
              icon={<CloseCircleOutlined />}
              type='primary'
              style={{ margin: '0 0.5rem' }}
              shape='circle'
              onClick={() => {
                setSearchValue('');
                setRefreshComponent(prevState => !prevState);
              }}></Button>
          )}
          <PriorityPopover
            priorityFilter={priorityFilter}
            setPriorityFilter={setPriorityFilter}
            handlePriorityFilter={handlePriorityFilter}
            handleClearPriority={handleClearPriority}
            popoverVisible={popoverVisible}
            setPopoverVisible={setPopoverVisible}
          />
          <Select
            className='filter-status'
            placeholder='Filter Status by..'
            value={requestStatus}
            onChange={status => {
              setRequestStatus(status);
              setRefreshComponent(prevState => !prevState);
            }}
            allowClear>
            {Object.keys(REQUEST_STATUS).map(status => (
              <Option value={REQUEST_STATUS_VALUES[status]} key={REQUEST_STATUS_VALUES[status]}>
                {REQUEST_STATUS[status]}
              </Option>
            ))}
          </Select>
          <Select
            className='filter-status'
            placeholder='Filter Source Type by..'
            value={selectedSourceType}
            onChange={srcType => {
              setSelectedSourceType(srcType);
              setRefreshComponent(prevState => !prevState);
            }}
            loading={sourceTypeLoading}
            allowClear>
            {sourceTypes.map(srcType => (
              <Option value={srcType} key={srcType}>
                {srcType}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ marginTop: '24px' }}>
        <Table
          columns={columns}
          dataSource={requestDetails}
          loading={tableLoading}
          bordered
          scroll={{ x: true }}
          rowKey={obj => obj.request_id}
          pagination={{
            onChange: (page, size) => getRequests(page, size, requestStatus),
            showSizeChanger: true,
            onShowSizeChange: (current, size) => getRequests(current, size, requestStatus),
            total: pageDetails.count
          }}
        />
      </div>
    </LayoutComponent>
  );
};

export default Home;
