import { message } from 'antd';
import { object, int } from 'prop-types';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css'

/**
 * @name getDateString
 * @function
 * @description Get date string from timestamp
 * @param {int} timestamp timestamp
 */
export const getDateTimeString = (timestamp, withTime = false, allNumbers = false, sendAsParams = false) => {
  const date = new Date(timestamp);
  const month = date.toLocaleString('default', { month: 'short' });
  const monthNum = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (parseInt(hours) < 10) {
    hours = `0${hours}`;
  }
  if (parseInt(minutes) < 10) {
    minutes = `0${minutes}`;
  }

  let dateString = `${day}/${month}/${year}`;

  if (allNumbers) {
    dateString = `${day}/${monthNum}/${year}`;
  }

  if (withTime) {
    dateString += ` | ${hours}:${minutes}`;
  }

  if (sendAsParams) {
    dateString = dateString.replaceAll('/', '-');
  }
  return dateString;
};

export const copyToClicpboard = data => {
  window.navigator.clipboard
    .writeText(JSON.stringify(data))
    .then(() => message.success('Copied to clipboard'))
    .catch(() => message.error('There was some error in copying'));
};

export const DisplayJson = props => {
  return (
    <div>
      <JsonView src={props.data} theme="winter-is-coming" displaySize="collapsed" collapsed={props.collapsedSize} style={{"color": "white"}}/>
    </div>
  );
};
export const formatCurrentDate = () => {
  return (
    new Date().toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }) +
    ' at ' +
    new Date().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    })
  );
};
export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  message.success(fileName + ' Successfully downloaded');
};

DisplayJson.propTypes = {
  data: object,
  collapsedSize: int
};

DisplayJson.defaultProps = {
  data: {},
  collapsedSize: 2
};

export const interpolate = function (theString, argumentArray) {
  var regex = /%s/;
  var _r = function (p, c) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(_r, theString);
};
